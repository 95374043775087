import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import PageHeader from '../utils/PageHeader'
import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query {
      img: file(relativePath: { eq: "pageheader.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="404: Not found" />
      <PageHeader img={data.img.childImageSharp.fluid}>
        <div className="container">
          <h1 className="portfolio-heading">404</h1>
        </div>
      </PageHeader>
      <section>
        <div className="container">
          <div className="row center">
            <h1>404</h1>
            <h4>
              Yikes! I've ventured the entire Universe but couldn't find what
              you were looking for!
            </h4>
            <Link
              className="waves-effect waves-light btn-large home-btn"
              style={{ marginTop: '45px' }}
              to="/"
            >
              Get back to Home
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
